import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useTheme, Box, Grid, Button, useMediaQuery, Paper, CardContent, Card, Typography } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { UploadImages, IImage } from 'shared/components/uploadImage';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ITaskModel, TaskFlag, TaskStatus, TaskType, RecurringType, DayOfWeek } from '@dayone/models';
import {
  taskSlice,
  useAppSelector,
  teamSlice,
  useAppDispatch,
  ICreateOrEditTask,
  settingSlice,
  featureFlagSlice,
} from '@dayone/redux';
import TaskPaper from './components/TaskPaper';
import { v4 as uuidv4 } from 'uuid';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import moment from 'moment';
import { useSnackbar } from 'shared/components/hooks';

export interface ITaskFormikValues {
  name: string;
  description: string;
  branchID: string;
  flag: TaskFlag;
  type: TaskType;
  status: TaskStatus;
  taskCompletedAt: string | null;
  images: IImage[];
  subTaskItems: { content: string; id: string | null }[];
  completedBy: string | null;
  imageSubmission: boolean;
  startDate: string | null;
  endDate: string | null;
  recurringDayOfWeek: DayOfWeek[] | null;
  recurringRepeat: Number | null;
  recurringType: RecurringType | null;
  occurrentDate: string | null;
  nextOccurrent: string | null;
  isTaskHasEndDate: boolean;
}

export default function TaskUpdate() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const navigate = useNavigate();
  const { taskID = '' } = useParams();
  const companyId = useAppSelector<any>(settingSlice.selectActiveWorkspaceID);
  const featureFlag = useAppSelector(featureFlagSlice.selectFeatureFlags);
  const { numberOfFileLimit, filesizeLimit } = featureFlag.task;
  const dispatch = useAppDispatch();
  const enqueueSnackbar = useSnackbar();
  const task: ITaskModel | null = useAppSelector<any>(taskSlice.selectTaskByID)(taskID);
  const branches = useAppSelector<any>(teamSlice.selectTeamsOverview).map((team: any) => ({
    id: team.teamId,
    name: team.name,
  }));

  const [isCloning, setIsCloning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showMessage = useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        variant: 'success',
      });
    },
    [enqueueSnackbar]
  );

  const onSubmit = (values: ITaskFormikValues) => {
    if (isCloning) {
      cloneTask(values);
    } else {
      if (task && task.taskID) {
        updateTask(values);
      } else {
        createTask(values);
      }
    }
  };

  const createNewSubTaskItem = useCallback((order: number) => ({ content: '', id: null }), []);

  const formInitialValues: ITaskFormikValues = task
    ? {
        ...task,
        flag: task.flag,
        type: task.type,
        images: task.images.map((image) => ({ url: image, action: 'UNCHANGE' })),
        subTaskItems:
          !task.subTaskItems || task.subTaskItems.length === 0 ? [createNewSubTaskItem(1)] : task.subTaskItems,
        isTaskHasEndDate: !!task.endDate,
      }
    : {
        name: '',
        description: '',
        branchID: '',
        flag: TaskFlag.nothing,
        type: TaskType.adHoc,
        status: TaskStatus.open,
        taskCompletedAt: null,
        images: [],
        subTaskItems: [createNewSubTaskItem(1)],
        completedBy: null,
        imageSubmission: false,
        startDate: null,
        endDate: null,
        recurringDayOfWeek: [],
        recurringRepeat: null,
        recurringType: null,
        occurrentDate: null,
        nextOccurrent: null,
        isTaskHasEndDate: false,
      };

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: validationFormSchema,
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  const updateTask = useCallback(
    (values: ITaskFormikValues) => {
      if (companyId && task?.taskID) {
        formik.setSubmitting(true);
        const taskToUpdate: ICreateOrEditTask = {
          taskID: task.taskID,
          companyID: task.companyID,
          branchID: values.branchID,
          description: values.description,
          flag: values.flag,
          images: values.images.map((image) => ({ file: image.file ?? null, url: image.url, action: image.action })),
          name: values.name,
          type: values.type,
          status: values.status,
          taskCompletedAt: values?.taskCompletedAt || null,
          subTaskItems: values?.subTaskItems
            ? values.subTaskItems.map((item) => ({
                content: item.content,
                id: item.id ?? uuidv4(),
              }))
            : [],
          completedBy: values?.completedBy || null,
          imageSubmission: values?.imageSubmission,
          startDate: values?.startDate?.toString() || '',
          endDate: values?.endDate?.toString() || '',
          recurringDayOfWeek: values?.recurringDayOfWeek || null,
          recurringRepeat: values?.recurringRepeat || null,
          recurringType: values?.recurringType,
          occurrentDate: values?.occurrentDate || null,
          nextOccurrent: values?.nextOccurrent || null,
        };

        dispatch(taskSlice.updateTask(taskToUpdate))
          .then(() => {
            showMessage(`The task has been updated successfully!`);
            navigate(-1);
          })
          .finally(() => {
            formik.setSubmitting(false);
          });
      }
    },
    [companyId, task, dispatch, showMessage, navigate, formik]
  );

  const cloneTask = useCallback(
    async (values: ITaskFormikValues) => {
      if (companyId) {
        formik.setSubmitting(true);

        const task: ICreateOrEditTask = {
          taskID: null,
          companyID: companyId,
          branchID: values.branchID,
          description: values.description,
          flag: values.flag,
          images: values.images.map((image) => ({ file: image.file ?? null, url: image.url, action: image.action })),
          name: values.name,
          type: values.type,
          status: values.status,
          taskCompletedAt: values?.taskCompletedAt || null,
          subTaskItems: values?.subTaskItems
            ? values.subTaskItems.map((item) => ({
                content: item.content,
                id: item.id ?? uuidv4(),
              }))
            : [],
          completedBy: values?.completedBy || null,
          imageSubmission: values?.imageSubmission,
          startDate: values?.startDate || '',
          endDate: values?.endDate || '',
          recurringDayOfWeek: values?.recurringDayOfWeek || null,
          recurringRepeat: values?.recurringRepeat || null,
          recurringType: values?.recurringType,
          occurrentDate: values?.occurrentDate || null,
          nextOccurrent: values?.nextOccurrent || null,
        };

        dispatch(taskSlice.createTask({ task, isCloning: true }))
          .then((taskDocRef: { payload: any }) => {
            showMessage(`The task has been cloned successfully!`);
            navigate(`/tasks/edit/${taskDocRef.payload.id}`);
          })
          .finally(() => {
            formik.setSubmitting(false);
            setIsCloning(false);
          });
      }
    },
    // eslint-disable-next-line
    [companyId, dispatch, showMessage, navigate, setIsCloning]
  );

  const createTask = useCallback(
    (values: ITaskFormikValues) => {
      if (companyId) {
        formik.setSubmitting(true);
        const task: ICreateOrEditTask = {
          taskID: null,
          companyID: companyId,
          branchID: values.branchID,
          description: values.description,
          flag: values.flag,
          images: values.images.map((image) => ({ file: image.file ?? null, url: image.url, action: image.action })),
          name: values.name,
          type: values.type,
          status: values.status,
          taskCompletedAt: values?.taskCompletedAt || null,
          subTaskItems: values?.subTaskItems
            ? values.subTaskItems.map((item) => ({
                content: item.content,
                id: item.id ?? uuidv4(),
              }))
            : [],
          completedBy: values?.completedBy || null,
          imageSubmission: values?.imageSubmission,
          startDate: values?.startDate?.toString() || '',
          endDate: values?.endDate?.toString() || '',
          recurringDayOfWeek: values?.recurringDayOfWeek || null,
          recurringRepeat: values?.recurringRepeat || null,
          recurringType: values?.recurringType,
          occurrentDate: values?.occurrentDate || null,
          nextOccurrent: values?.nextOccurrent || null,
        };
        dispatch(taskSlice.createTask({ task, isCloning: false }))
          .then(() => {
            showMessage(`The task has been created successfully!`);
            navigate('/tasks');
          })
          .finally(() => {
            formik.setSubmitting(false);
          });
      }
    },
    // eslint-disable-next-line
    [companyId, dispatch, showMessage, navigate]
  );

  const anySubTaskEmpty = useCallback(
    () => formik.values.subTaskItems.some((x) => !x.content || x.content === ''),
    [formik]
  );

  useEffect(() => {
    if (formik.errors) {
      const keys = Object.keys(formik.errors);
      if (keys.length > 0 && formik.isSubmitting && !formik.isValidating) {
        const selector = `[name="${keys[0]}"]`;
        const errorElement = document.querySelector(selector);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          (errorElement as HTMLElement).focus({ preventScroll: true });
        }
      }
    }
  }, [formik.errors, formik.isSubmitting, formik.isValidating]);

  const handleUploadImage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (files && files.length) {
        const newFiles = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          newFiles.push({ file: file, url: URL.createObjectURL(file), action: 'ADD' });
        }
        formik.setFieldValue('images', [...formik.values.images, ...newFiles]);
      }
    },
    [formik]
  );

  const handleRemove = useCallback(
    (image: IImage) => {
      if (image.action === 'ADD') {
        formik.setFieldValue(
          'images',
          formik.values.images.filter((img) => img.url !== image.url)
        );
      } else if (image.action === 'UNCHANGE') {
        const newImage = { ...image, action: 'REMOVE' };
        const index = formik.values.images.indexOf(image);
        if (index !== -1) formik.setFieldValue(`images[${index}]`, newImage);
      }
    },
    [formik]
  );

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleDelete = useCallback(() => {
    if (task && task.taskID) {
      setIsLoading(true);
      dispatch(taskSlice.deleteTask({ companyID: companyId, taskID: task.taskID }))
        .unwrap()
        .then(() => {
          navigate(-2);
          enqueueSnackbar('The task has been deleted!', {
            variant: 'warning',
          });
        })
        .catch((err) => {
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [task, dispatch, navigate, enqueueSnackbar, companyId]);

  const onClone = useCallback(() => {
    setIsCloning(true);
    formik.submitForm();
  }, [setIsCloning, formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        {/* Back button */}
        {taskID && (
          <Grid item xs={12}>
            <Box pb={2}>
              <Button color="primary" startIcon={<ArrowBackIcon />} onClick={handleBack}>
                {`BACK`}
              </Button>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} sm={12} lg={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12}>
              <TaskPaper
                showDelete={!!task}
                allowClone={!!task}
                onClone={onClone}
                creationLog={
                  !!task && (
                    <>
                      {task.createdAt !== '' && moment(task.createdAt).isValid() && (
                        <Typography variant="caption">
                          Posted on {moment(task.createdAt).format('D MMMM YYYY')} by {task.createdByDisplayName}
                          <br />
                        </Typography>
                      )}
                      {task.updatedAt !== '' && task.updatedAt !== task.createdAt && moment(task.updatedAt).isValid() && (
                        <Typography variant="caption">
                          Updated on {moment(task.updatedAt).format('D MMMM YYYY')} by {task.updatedByDisplayName}
                        </Typography>
                      )}
                    </>
                  )
                }
                onDelete={handleDelete}
                formik={formik}
                nameField={{
                  value: formik.values.name,
                  error: formik.touched.name && Boolean(formik.errors.name),
                  helpText: formik.touched.name && formik.errors.name,
                }}
                descriptionField={{
                  value: formik.values.description,
                  error: formik.touched.description && Boolean(formik.errors.description),
                  helpText: formik.touched.description && formik.errors.description,
                }}
                flagField={{
                  checked: formik.values.flag === TaskFlag.urgent,
                  onChange: (checked) => {
                    const value = checked ? TaskFlag.urgent : TaskFlag.nothing;
                    formik.setFieldValue('flag', value);
                  },
                }}
                branchField={{
                  value: formik.values.branchID,
                  branches: branches,
                  onChange: (value) => formik.setFieldValue('branchID', value),
                  error: formik.touched.branchID && Boolean(formik.errors.branchID),
                  helpText: formik.touched.branchID && formik.errors.branchID,
                }}
                handleChange={(fieldName: string, val: string) => formik.setFieldValue(fieldName, val)}
                typeField={{ value: formik.values.type, onChange: (value) => formik.setFieldValue('type', value) }}
                statusField={{
                  value: formik.values.status,
                  onChange: (value) => formik.setFieldValue('status', value),
                }}
                disableUrgentSwitch={!!task}
                subTaskItems={formik.values.subTaskItems}
                handleSubTaskChange={formik.handleChange}
                error={formik.errors.subTaskItems}
                touched={formik.touched.subTaskItems}
                addSubTask={() => {
                  if (!anySubTaskEmpty())
                    formik.setFieldValue('subTaskItems', [
                      ...formik.values.subTaskItems,
                      createNewSubTaskItem(formik.values.subTaskItems.length + 1),
                    ]);
                }}
                removeSubTask={(index) => {
                  const newSubTaskItem = [...formik.values.subTaskItems];
                  newSubTaskItem.splice(index, 1);
                  formik.setFieldValue('subTaskItems', newSubTaskItem);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} lg={4}>
          <Paper elevation={0}>
            <Card elevation={0}>
              <CardContent>
                <UploadImages
                  key={'uploadImage'}
                  text="UPLOAD IMAGE"
                  acceptType="image/*"
                  images={formik.values.images}
                  onUploadImage={handleUploadImage}
                  onRemove={(event) => handleRemove(event)}
                  // defaults to zero aka no limit
                  limit={numberOfFileLimit}
                  fileSizeLimit={filesizeLimit}
                  numberOfFileLimitError="Task.NumberOfFileLimit"
                  fileSizeLimitError="Task.FileSizeLimit"
                />
              </CardContent>
            </Card>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} lg={8}>
          <Button fullWidth={matches} type="submit" variant="contained" color="primary">
            {task ? 'Update' : 'Create task'}
          </Button>
        </Grid>
      </Grid>
      <ProgressIndicator loading={formik.isSubmitting || isLoading} />
    </form>
  );
}

let validationFormSchema = yup.object({
  name: yup.string().required('Task name is required'),
  description: yup.string().required('Description is required'),
  branchID: yup.string().required('Team is required'),
  startDate: yup.string().when('flag', {
    is: (flag: TaskFlag) => flag !== TaskFlag.urgent,
    then: yup.string().nullable().required('Date is required'),
    otherwise: yup.string().nullable(),
  }),
  subTaskItems: yup.array().when('subTasksEnable', {
    is: (enable: boolean) => enable,
    then: yup.array().of(
      yup.object({
        content: yup.string().required('Subtask is not nullable'),
      })
    ),
    otherwise: yup.array(),
  }),
  endDate: yup.string().when('type', {
    is: (type: TaskType) => type === TaskType.daily,
    then: yup.string().when('isTaskHasEndDate', {
      is: (enable: boolean) => enable,
      then: yup.string().nullable().required('End date is required'),
      otherwise: yup.string().nullable(),
    }),
    otherwise: yup.string().nullable(),
  }),
  recurringRepeat: yup.number().when('type', {
    is: (type: TaskType) => type === TaskType.daily,
    then: yup.number().required('Recurrence interval is required'),
    otherwise: yup.number().nullable(),
  }),
  recurringDayOfWeek: yup.array().when('recurringType', {
    is: (recurringType: RecurringType) => recurringType === RecurringType.WEEK,
    then: yup.array().min(1, 'Selection of schedule days is required'),
    otherwise: yup.array().nullable(),
  }),
});
