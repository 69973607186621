import env from 'shared/utilities/environment';

interface windowIntercom extends Window {
  Intercom: any;
}

const initialise = () => {
  const intercom: windowIntercom = Object.assign({ Intercom: {} }, window);

  intercom.Intercom('boot', {
    app_id: env.INTERCOM.id,
  });
};

const update = (page: string) => {
  const intercom: windowIntercom = Object.assign({ Intercom: {} }, window);
  intercom.Intercom(page);
};

const intercom = { initialise, update };

export default intercom;
