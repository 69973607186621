import FirebaseConfig, {
  appCheck,
  emulatorAuth,
  emulatorFirestore,
  emulatorFunctions,
  emulatorStorage,
  emulatorCustomStorage,
} from '@dayone/firebase';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import env from 'shared/utilities/environment';
import HookConfig from '@dayone/hooks';
import StoreConfig, { store, PersistGate, persistor } from '@dayone/redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { LicenseInfo } from '@material-ui/x-grid';
import { ProgressIndicator } from 'shared/components/progressIndicator';
import intercom from 'shared/utilities/intercom';
import analytics from 'shared/utilities/analytics';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';

Sentry.init({
  dsn: env.SENTRY.dsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: env.SENTRY.tracesSampleRate,
});

/**
 * This is the only console log allowed
 * To make sure the right config is passed in
 */
// eslint-disable-next-line no-console
console.log(`authDomain ${env.FIREBASE.config.authDomain}`);

// Init firebase to use it
const firebaseApp = FirebaseConfig(env.FIREBASE.config);

if (firebaseApp) {
  // Init hooks with configured firebase injected
  HookConfig(firebaseApp);
  StoreConfig(firebaseApp, env.FIREBASE.storageBucketConfig);
  // Init analytics
  analytics.config(firebaseApp);
  // Setup appCheck
  appCheck(firebaseApp, env.RECAPTCHA.key, env.RECAPTCHA.enableAppCheckDebug, env.RECAPTCHA.debugToken);

  // If using emulator
  if (env.FIREBASE.useFirebaseEmulator) {
    // eslint-disable-next-line no-console
    console.group('Emulator');
    emulatorAuth(firebaseApp);
    emulatorFirestore(firebaseApp);
    emulatorFunctions(firebaseApp);
    emulatorStorage(firebaseApp);
    emulatorCustomStorage(firebaseApp, Object.values(env.FIREBASE.storageBucketConfig));
    // eslint-disable-next-line no-console
    console.groupEnd();
  }
}

LicenseInfo.setLicenseKey(env.XGRID.license);
intercom.initialise();
if (env.MIXPANEL.id.length > 0) mixpanel.init(env.MIXPANEL.id);

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate loading={<ProgressIndicator />} persistor={persistor}>
            <BrowserRouter>
              <SnackbarProvider maxSnack={3}>
                <App />
              </SnackbarProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
