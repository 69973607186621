interface windowHotjar extends Window {
  hj: any;
}

const identify = (firebaseUser: any) => {
  if (firebaseUser?.uid) {
    const hotjar: windowHotjar = Object.assign({ hj: {} }, window);

    if (hotjar.hj) {
      hotjar.hj('identify', firebaseUser.uid, {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        phoneNumber: firebaseUser.phoneNumber,
        displayName: firebaseUser.displayName,
        photoURL: firebaseUser.photoURL,
      });
    }
  }
};

const hotjar = { identify };

export default hotjar;
